import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';

const useListSchedule = () => {
	const { RequestUseCase, endpoints } = useAxios();
	const initialFieldValues = {
		technicals: [],
		positions: [],
		countries: [],
		states: [],
		municipalities: [],
		typeDocument: []
	};

	const [fieldValues, setFieldValues] = useState(initialFieldValues);

	const _filterActive = (data) => {
		return data.filter((item) => item.estado === 1);
	};

	const updateFieldValues = (key, value) => {
		setFieldValues((prevFieldValues) => ({
			...prevFieldValues,
			[key]: value
		}));
	};

	const _fetchAndMapData = async (endpoint, mapFunction, key) => {
		try {
			const data = await RequestUseCase.get(endpoint);
			const filteredData = _filterActive(data);
			const mappedData = filteredData.map(mapFunction).filter((i) => !isUndefined(i));
			updateFieldValues(key, mappedData);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getAllTechnicals = () => {
		_fetchAndMapData(
			endpoints.technical.getAllTechnical,
			(item) => {
				if (item.idEmpleado) {
					const name = `${item.idEmpleado.primerNombre} ${item.idEmpleado.segundoNombre || ''}`;
					const surname = `${item.idEmpleado.primerApellido} ${
						item.idEmpleado.segundoApellido || ''
					}`;
					return {
						id: item.idTecnico,
						label: `${name} ${surname}`
					};
				}
			},
			'technicals'
		);
	};

	const getAllCountries = () => {
		_fetchAndMapData(
			endpoints.zones.getAllCountries,
			(item) => ({
				id: item.idPais,
				label: item.nombrePais
			}),
			'countries'
		);
	};

	const getAllPositions = () => {
		_fetchAndMapData(
			endpoints.cargos.getAllCargos,
			(item) => ({
				id: item.idCargo,
				label: item.nombre
			}),
			'positions'
		);
	};

	const getAllStatesByCountry = (idCountry) => {
		RequestUseCase.get(endpoints.zones.getAllDepartmentsByIdCountry(idCountry))
			.then((data) => {
				const filteredData = _filterActive(data);
				const statesMap = filteredData.map((item) => ({
					id: item.idDepartamento,
					label: `${item.nombre}`
				}));
				updateFieldValues('states', statesMap);
			})
			.catch((error) => {
				console.error('Error fetching states data:', error);
			});
	};

	const getAllMunicipies = () => {
		_fetchAndMapData(
			endpoints.zones.getAllMunicipalities,
			(item) => ({
				id: item.idMunicipio,
				label: item.nombre
			}),
			'municipalities'
		);
	};

	const getAllTypeDocument = () => {
		RequestUseCase.get(endpoints.institutions.getAllTypeDocument).then((resp) => {
			const dataMap = resp.map((item) => ({
				id: item.idTipoDocumento,
				label: item.nombre
			}));
			updateFieldValues('typeDocument', dataMap);
		});
	};

	const _getAllData = () => {
		getAllTechnicals();
		getAllCountries();
		getAllPositions();
		getAllMunicipies();
		getAllTypeDocument();
	};

	useEffect(() => _getAllData(), []);

	return {
		fieldValues,
		getAllStatesByCountry
	};
};

export default useListSchedule;
