import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router-dom';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';
import CostProductProvider from 'models/CostProductProvider';
// Import Models
import Product from 'models/Product';
// Import libs
import { useForm } from '@formiz/core';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
// Import de services
import rawCurrency from 'common/utils/rawCurrency';
import { CODEREGEX, NOSPACEREGEX } from 'common/validators/Regex';
import { isEmpty } from 'lodash';
import paths from 'services/paths';

export default function CreateProductViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const style = {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3
	};

	const myForm = useForm();
	// Modal config
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const [costo, setCosto] = useState('');
	const [iva, setIva] = useState('');
	const [formCostProductProviderData, setFormCostProductProviderData] = useState(
		new CostProductProvider()
	);
	// Call context TableMinimalContext
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);
	const navigate = useNavigate();
	// useLanguage
	const { formatterText } = useLang();
	// Example of form data
	const [formData, setFormData] = useState(new Product());
	// Los valores que usará el multiselect
	const [selectedMultiSearch, setSearchSelectedMulti] = useState([]);
	// Los valores que usará el select
	const [selectedSearch, setSearchSelected] = useState([
		{
			proveedor: [],
			unidadMedida: [],
			subCategoriasProducto: [],
			tipoMonedas: []
		}
	]);

	const titlesTableCostProduct = [
		formatterText('table.title.provider', 'Proveedor'),
		formatterText('p.product.unit.meassurement', 'Unidad de medida'),
		formatterText('table.title.cost', 'Costo'),
		formatterText('table.title.iva', 'Iva(%)'),
		formatterText('table.title.currency', 'Moneda'),
		formatterText('table.actions', 'Acciones')
	];

	const [selectorMultiValues, setSelectorMultiValues] = useState();
	// espera a que cargue los valores del multiselect
	const { loading, toggleLoading } = useGetData();
	const [auxData, setAuxData] = useState({
		idSubCategoriaProducto: 0,
		idProveedor: 0,
		idUnidadMedida: 0,
		idMoneda: 0
	});

	useEffect(() => {
		setCurrentDataTable([]);
		getUnitMeasure();
		getBarcodes();
		getSubProductCategory();
		getProviders();
		getMonedas();
	}, []);

	const getMonedas = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.listPrices.getAllMonedas)
			.then((res) => {
				const newArray = [];
				// iterate response and get only the values that are active
				res.map((item) => {
					const data = {
						value: item.idMoneda,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true
					};
					return newArray.push(data);
				});
				setSearchSelected((prev) => ({
					...prev,
					tipoMonedas: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getUnitMeasure = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.getUnidadMedida)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idUnidadMedida,
							label: `${item.descripcion} - ${item.abreviatura}`,
							isFixed: true
						});
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					unidadMedida: newArray
				}));
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getBarcodes = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.barcodes.getAllBarcodes)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCodigoBarras,
							label: item.codigo,
							isFixed: true
						});
					}
				});
				setSearchSelectedMulti(newArray);
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getSubProductCategory = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.subProductCategory.getAllSubProductCategory)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idSubCategoriaProducto,
							label: ` ${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				//  set in setSearchSelected in the value  subCategoriasProducto and dont delete the previous value
				setSearchSelected((prev) => ({
					...prev,
					subCategoriasProducto: newArray
				}));

				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getProviders = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.providers.getAllProviders)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idProveedor,
							label: `${item.nombreRazonSocial} - ${item.identificacion}`,
							isFixed: true
						});
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					proveedor: newArray
				}));

				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};
	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
		const data = {
			...formData,
			nombre:
				myForm.fields?.nombre?.value?.replaceAll(/\s{2,}/gi, ' ') ||
				formData.nombre.replaceAll(/\s{2,}/gi, ' '),
			descripcion:
				myForm.fields?.descripcion?.value?.replaceAll(/\s{2,}/gi, ' ') ||
				formData.descripcion.replaceAll(/\s{2,}/gi, ' '),
			sku:
				myForm.fields?.sku?.value?.replaceAll(/\s{2,}/gi, ' ') ||
				formData.sku.replaceAll(/\s{2,}/gi, ' '),
			subCategoriaProducto: {
				idSubCategoriaProducto:
					myForm.fields?.idSubCategoriaProducto?.value || auxData.idSubCategoriaProducto.value
			},
			usuarioCreacion: parseInt(formData.usuarioCreacion),
			usuarioModificacion: null
		};
		if (
			selectorMultiValues.length > 0 &&
			myForm.fields?.nombre?.value != null &&
			myForm.fields?.sku?.value != null &&
			myForm.fields?.idSubCategoriaProducto?.value != null
		) {
			const barcodeExists = selectedMultiSearch.some((item) =>
				selectorMultiValues.map((item2) => item2.label).includes(item.label)
			);
			if (barcodeExists) {
				HandleOnError(formatterText('alert.message.barcode.duplicated'));
				return;
			}
			createProduct(data);
		} else {
			HandleOnError(formatterText('alert.message.missing.fill'));
		}
	};

	const createProduct = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.post(endpoints.products.addProduct, data)
						.then((res) => {
							// aqui se llama el servicio para asociar el producto con el proveedor
							associateBarcode(res.idProducto, resolve, reject);
						})
						.catch((err) => {
							if (err.response.status === 400) {
								HandleOnError(formatterText('alert.message.sku.repetead'));
							} else {
								HandleOnError(formatterText('Error al crear el registro'));
							}
							console.error(err);
						});
				});
			}
		});
	};

	const associateBarcode = (id, resolve, reject) => {
		if (selectorMultiValues.length > 0) {
			const promesas = selectorMultiValues.map(
				(item) =>
					new Promise((resl, rej) => {
						const data = {
							producto: {
								idProducto: id
							},
							codigo: item.value?.toLowerCase()
						};

						RequestUseCase.post(endpoints.barcodes.addBarcode, data)
							.then((response) => {
								resl(response);
							})
							.catch((err) => {
								rej(err);
							});
					})
			);

			Promise.all(promesas)
				.then(() => {
					resolve(
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general'),
							text: formatterText('alert.message.confirm.created.general'),
							confirmButtonText: formatterText('alert.button.continue'),
							allowOutsideClick: false,
							executeFunction: () => associateCostProduct(id)
						})
					);
				})
				.catch((err) => {
					if (err.response.status === 412) {
						reject(HandleOnError(err.response.data));
					} else if (err.response.status === 400) {
						HandleOnError(
							formatterText(
								'Error al crear el producto, comprobar duplicidad en los códigos existentes'
							)
						);
					} else {
						reject(HandleOnError(formatterText('alert.message.failed.general')));
					}
				});
		} else {
			resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('alert.message.confirm.created.general'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () => associateCostProduct(id)
				})
			);
		}
	};

	const associateCostProduct = (id) => {
		if (currentDataTable.length > 0) {
			const promesas = currentDataTable.map(
				(item) =>
					new Promise((resl) => {
						const data = {
							idPrecioProductoProveedor: null,
							idProveedor: {
								idProveedor: item.idProveedor.value
							},
							idProducto: {
								idProducto: id
							},
							idUnidadMedida: {
								idUnidadMedida: item.idUnidadMedida.value
							},
							idMoneda: {
								idMoneda: item.idMoneda.value
							},
							costo: rawCurrency(item.costo),
							iva: rawCurrency(item.iva) || 0,
							estado: 1,
							fechaCreacion: new Date(),
							fechaModificacion: null,
							usuarioCreacion: parseInt(formData.usuarioCreacion),
							usuarioModificacion: null
						};

						RequestUseCase.post(endpoints.costProductProvider.addCostProductProvider, data)
							.then((response) => {
								resl(response);
							})
							.catch((err) => {
								HandleOnError(err.response.data);
							});
					})
			);

			Promise.all(promesas)
				.then(() => {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general'),
						text: formatterText('alert.message.associations.general'),
						confirmButtonText: formatterText('alert.button.continue'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.products)
					});
				})
				.catch((err) => {
					if (err.response.status === 412 || err.response.status === 400) {
						HandleOnError(err.response.data);
					} else {
						HandleOnError(formatterText('alert.message.failed.general'));
					}
				});
		} else {
			navigate(paths.products);
		}
	};

	const [validate, setValidate] = useState(false);

	const validateFieldFormProvData = () => {
		const { costo, iva, idUnidadMedida, idMoneda, idProveedor } = formCostProductProviderData;
		const result = costo && iva && idUnidadMedida && idMoneda && idProveedor;
		setValidate(result);
	};

	useEffect(() => validateFieldFormProvData(), [formCostProductProviderData]);

	const handleAddItemMinimal = () => {
		if (!isEmpty(validate)) {
			const existData = currentDataTable.filter(({ idProveedor, idUnidadMedida }) => {
				const formDataIdProveedor = formCostProductProviderData.idProveedor?.value;
				const formDataIdUnidadMedida = formCostProductProviderData.idUnidadMedida?.value;
				return (
					(idProveedor?.value === formDataIdProveedor &&
						idUnidadMedida?.value === formDataIdUnidadMedida) ||
					(idProveedor === formDataIdProveedor && idUnidadMedida === formDataIdUnidadMedida)
				);
			});

			if (existData.length > 0) {
				HandleOnError(formatterText('p.label.title.costo.exist'));
			} else {
				setCurrentDataTable([...currentDataTable, formCostProductProviderData]);
				setResultsTableSearch([...currentDataTable, formCostProductProviderData]);
				setFormCostProductProviderData({
					costo: '',
					idMoneda: '',
					iva: '',
					idPrecioProductoProveedor: uuidv4()
				});
				setAuxData((prevState) => ({
					...prevState,
					idUnidadMedida: null,
					idProveedor: null,
					idMoneda: null
				}));
				setIva('');
				setCosto('');
			}
		} else {
			HandleOnError(formatterText('p.label.title.costo'));
		}
	};

	const handleChangeMulti = (val) => {
		// Filtrar los elementos según el regex y formar un nuevo arreglo
		const valNew = val.filter((elem) => {
			const toLower = elem.value;

			const formaterWord = toLower.toString().match(CODEREGEX)
				? true
				: toLower.toString().match(NOSPACEREGEX)
				? true
				: false;
			return formaterWord;
		});

		// Establecer el nuevo arreglo como valor del estado
		setSelectorMultiValues(valNew);
	};

	const formatSelectorMultiValues = (values) => {
		// Verificar si `values` es un arreglo y tiene elementos
		if (Array.isArray(values) && values.length > 0) {
			// Mapear sobre los valores y formatearlos según sea necesario
			return values.map((item) => {
				// Verificar si el valor es una cadena antes de llamar a `toLowerCase()`
				const formattedItem = {
					...item,
					label: typeof item.label === 'string' ? item.label.toLowerCase() : item.label,
					value: typeof item.value === 'string' ? item.value.toLowerCase() : item.value
				};
				return formattedItem;
			});
		}
		// Si `values` no es un arreglo o no tiene elementos, devolverlo como está
		return values;
	};

	const handleCostoChange = (event) => {
		const inputCosto = event.target.value;
		const costoFormateado = inputCosto.replace(/[^\d,]/g, '').replace(/(,.*)\,/g, '$1');
		setCosto(costoFormateado);
		const costoDecimal = parseFloat(costoFormateado.replace(',', '.'));
		const costoConDosDecimales = costoDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
		setFormCostProductProviderData({
			...formCostProductProviderData,
			[event.target.name]: `$${costoConDosDecimales}`
		});
	};

	const formatCurrency = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}
		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};

	const handleIvaChange = (event) => {
		const inputIva = event.target.value;

		const ivaFormateado = inputIva.replace(/[^\d,]/g, '');
		setIva(ivaFormateado);

		const ivaDecimal = parseFloat(ivaFormateado.replace(',', '.'));
		const ivaConDosDecimales = ivaDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setFormCostProductProviderData({
			...formCostProductProviderData,
			[event.target.name]: ivaConDosDecimales
		});
	};

	const formatIvaField = (value) => {
		const taxs = value.toString().split('.');
		let taxValue = `${taxs[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (taxs.length > 1) {
			taxValue += `.${taxs[1]}`;
		}
		const Decimal = taxValue.split(',');
		const intPart = Decimal[0];
		const floatPart = Decimal[1];

		if (floatPart) {
			taxValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return taxValue;
	};

	useEffect(() => {
		if (auxData.idProveedor !== null || auxData.idUnidadMedida !== null) {
			setFormCostProductProviderData({
				...formCostProductProviderData,
				idProveedor: auxData.idProveedor,
				idUnidadMedida: auxData.idUnidadMedida,
				idMoneda: auxData.idMoneda
			});
		}
	}, [auxData]);

	return {
		auxData,
		costo,
		currentDataTable,
		formatCurrency,
		formatIvaField,
		formatterText,
		formData,
		handleAddItemMinimal,
		handleChangeMulti,
		handleClose,
		handleCostoChange,
		handleIvaChange,
		handleOpen,
		handlerTextDescription,
		handleSubmit,
		iva,
		loading,
		myForm,
		navigate,
		open,
		selectedMultiSearch,
		selectedSearch,
		selectorMultiValues,
		setAuxData,
		style,
		titlesTableCostProduct,
		formatSelectorMultiValues
	};
}
