import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import { useNavigate, useParams } from 'react-router-dom';
// Import Components
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';
// Import Models
import CostProductProvider from 'models/CostProductProvider';
import Product from 'models/Product';
// Import libs
import { useForm } from '@formiz/core';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
// Import de services
import rawCurrency from 'common/utils/rawCurrency';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import { CODEREGEX, SKUREGEX } from 'common/validators/Regex';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import { isEmpty } from 'lodash';
import paths from 'services/paths';
export default function UpdateProductViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const style = {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3
	};

	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [costo, setCosto] = useState('');
	const [iva, setIva] = useState('');

	const { setDataTable } = useSeachContext();

	const myForm = useForm();
	const [formCostProductProviderData, setFormCostProductProviderData] = useState(
		new CostProductProvider()
	);
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);
	const navigate = useNavigate();
	// useLanguage
	const { formatterText, errorProcess, newItemCreated, resourceNotFound } = useLangv2();
	// Example of form data
	const [formData, setFormData] = useState(new Product());
	// Los valores que usará el select
	const [selectedSearch, setSearchSelected] = useState([
		{
			proveedor: [],
			unidadMedida: [],
			subCategoriasProducto: [],
			tipoMonedas: []
		}
	]);

	const titlesTableCostProduct = [
		formatterText('table.title.provider', 'Proveedor'),
		formatterText('p.product.unit.meassurement', 'Unidad de medida'),
		formatterText('table.title.cost', 'Costo'),
		formatterText('table.title.iva', 'Iva(%)'),
		formatterText('table.title.currency', 'Moneda'),
		formatterText('table.actions', 'Acciones')
	];

	// Setea los valores del multiselect
	const [selectorMultiValues, setSelectorMultiValues] = useState([]);
	// Setea los valores del select
	const [selectorValues] = useState([]);
	// espera a que cargue los valores del multiselect
	const { loading, toggleLoading } = useGetData();
	const [auxData, setAuxData] = useState({
		idSubCategoriaProducto: 0,
		idProveedor: 0,
		idUnidadMedida: 0,
		idMoneda: 0
	});

	// toggle state
	const [active, setActive] = useState(true);
	// Setea los valores del multiselect de los paises
	const [selectValues, setSelectValues] = useState([]);
	// Auxiliar para el tamaño del multi create selector
	const [auxSelectorMulti, setAuxSelectorMulti] = useState([]);
	// get id from url
	const { id } = useParams();
	const [subcategoriesData, setSubcategoriesData] = useState([]);

	useEffect(() => {
		getDataProductCategory();
		getDataMiniTable(id);
		getMonedas();
		getAllBarcodes();
		getSubProductCategory();
		getProviders();
		getUnitMeasure();
	}, []);

	useEffect(() => {
		getProductById(id);
		getDataMiniTable(id);
	}, [id]);

	const getMonedas = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.listPrices.getAllMonedas)
			.then((res) => {
				const newArray = [];
				// iterate response and get only the values that are active
				res.map((item) => {
					const data = {
						value: item.idMoneda,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true
					};
					return newArray.push(data);
				});
				setSearchSelected((prev) => ({
					...prev,
					tipoMonedas: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const setProductForm = (data) => {
		setFormData({
			idProducto: data.idProducto,
			idSubCategoriaProducto: data.subCategoriaProducto?.idSubCategoriaProducto
				? data.subCategoriaProducto.idSubCategoriaProducto
				: null,
			sku: data.sku,
			nombre: data.nombre,
			descripcion: data.descripcion,
			estado: data.estado,
			fechaRegistro: data.fechaRegistro,
			fechaModificacion: data.fechaModificacion,
			usuarioCreacion: data.usuarioCreacion,
			usuarioModificacion: data.usuarioModificacion
		});
	};
	//get data from LocalStorage
	const getDataProductCategory = () => {
		const productData = JSON.parse(localStorage.getItem('dataUpdate'));

		if (productData) {
			setProductForm(productData);
			setActive(productData.estado);
			getSelectCurrentValues(productData);
			getBarcodes(productData?.codigoBarras);
		} else {
			navigate(paths.products);
		}
	};
	//get data from api
	const getProductById = (id) => {
		RequestUseCase.get(endpoints.products.getProductById(id))
			.then((res) => {
				if (res !== null) {
					setProductForm(res);
					setActive(res.estado);
					getSelectCurrentValues(res);
					getBarcodes(res?.codigoBarras);
				} else {
					resourceNotFound();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const formatIvaToTable = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `,${parts[1]}`;
		}

		return formattedValue;
	};

	const getDataMiniTable = (id) => {
		RequestUseCase.get(endpoints.costProductProvider.getCostProductProviderByProduct(id))
			.then((data) => {
				const aux = data.map((item) => {
					return {
						idPrecioProductoProveedor: item.idPrecioProductoProveedor,
						idProveedor: {
							label: `${item.idProveedor.nombreRazonSocial} - ${item.idProveedor.identificacion}`,
							value: item.idProveedor.idProveedor
						},
						idUnidadMedida: {
							label: `${item.idUnidadMedida.descripcion} - ${item.idUnidadMedida.abreviatura}`,
							value: item.idUnidadMedida.idUnidadMedida
						},
						costo: formatCurrency(item.costo.toString()),
						idMoneda: item.idMoneda
							? {
									label: `${item.idMoneda.nombre} - ${item.idMoneda.codigo}`,
									value: formatIvaToTable(item.idMoneda.idMoneda.toString())
							  }
							: null,
						iva: formatIvaToTable(item.iva.toString())
					};
				});
				setCurrentDataTable(aux);
				setResultsTableSearch(aux);
				setDataTable(aux);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getSelectCurrentValues = (item) => {
		setSelectValues({
			value: item.subCategoriaProducto?.idSubCategoriaProducto,
			label: ` ${item.subCategoriaProducto?.nombre} - ${item.subCategoriaProducto?.codigo}`,
			isFixed: true
		});
		setAuxData({
			idSubCategoriaProducto: item.subCategoriaProducto?.idSubCategoriaProducto,
			label: ` ${item.subCategoriaProducto?.nombre} - ${item.subCategoriaProducto?.codigo}`,
			isFixed: true
		});
	};

	const getBarcodes = (data) => {
		if (data === undefined) {
			return;
		}
		const barcodes = [];
		data.forEach((item) => {
			barcodes.push({
				label: item.codigo,
				value: item.idCodigoBarras,
				codigo: item.codigo,
				estado: item.estado,
				fechaModificacion: item.fechaModificacion,
				fechaRegistro: item.fechaRegistro,
				idCodigoBarras: item.idCodigoBarras,
				usuarioCreacion: item.usuarioCreacion,
				usuarioModificacion: item.usuarioModificacion
			});
		});
		setSelectorMultiValues(barcodes);
		setAuxSelectorMulti(barcodes.length);
	};

	const getSubProductCategory = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.subProductCategory.getAllSubProductCategory)
			.then((res) => {
				setSubcategoriesData(res);
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idSubCategoriaProducto,
							label: ` ${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				//  set in setSearchSelected in the value  subCategoriasProducto and dont delete the previous value
				setSearchSelected((prev) => ({
					...prev,
					subCategoriasProducto: newArray
				}));

				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getProviders = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.providers.getAllProviders)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idProveedor,
							label: ` ${item.nombreRazonSocial} - ${item.identificacion}`,
							isFixed: true
						});
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					proveedor: newArray
				}));

				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getUnitMeasure = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.getUnidadMedida)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idUnidadMedida,
							label: `${item.descripcion} - ${item.abreviatura}`,
							isFixed: true
						});
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					unidadMedida: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		if (e.target.value.match(/^[a-zA-Z0-9 \-ñáéíóúÁÉÍÓÚÑ]*$/) !== null) {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleSku = (e) => {
		const { value } = e.target;
		const lowerCaseValue = value.toLowerCase();
		if (lowerCaseValue.match(SKUREGEX) || lowerCaseValue === '') {
			HandleInput(
				{ target: { name: e.target.name, value: lowerCaseValue } },
				SKUREGEX,
				formData,
				setFormData
			);
		}
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		const selectedSubCategory = subcategoriesData?.find(
			(item) => item.idSubCategoriaProducto === (auxData || selectValues.value)
		);

		if (!myForm.isValid && (formData.nombre === '' || formData.sku === '')) {
			HandleOnError(formatterText('alert.message.filling.fields', 'ERROR AL LLENAR LOS CAMPOS'));
		} else {
			const data = {
				...formData,
				nombre:
					myForm.fields?.nombre?.value?.replaceAll(/\s{2,}/gi, ' ') ||
					formData.nombre.replaceAll(/\s{2,}/gi, ' '),
				descripcion: formData.descripcion.replaceAll(/\s{2,}/gi, ' '),
				sku: formData.sku.replaceAll(/\s{2,}/gi, ' '),
				subCategoriaProducto: selectedSubCategory,
				estado: active ? 1 : 0,
				usuarioModificacion: parseInt(Cookie.get('idUsuario'))
			};
			if (selectorMultiValues.length > 0) {
				putProduct(data);
			} else {
				HandleOnError(formatterText('alert.message.barcode', 'Debes ingresar codigo de barras'));
			}
		}
	};

	const putProduct = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.update.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.put(endpoints.products.updateProduct, data)
						.then((res) => {
							associateBarcode(res.idProducto, resolve, reject);
						})
						.catch((err) => {
							if (err.response.status === 400) {
								HandleOnError(formatterText('alert.message.sku.repetead'));
							} else {
								HandleOnError(formatterText('Error al crear el registro'));
							}
							console.error(err);
						});
				});
			}
		});
	};

	const associateBarcode = (id, resolve, reject) => {
		if (selectorMultiValues.length > 0) {
			const promesas = selectorMultiValues.map(
				(item) =>
					new Promise((resl, rej) => {
						const data = {
							producto: {
								idProducto: id
							},
							codigo: item.label?.toLowerCase()
						};
						if (item.__isNew__) {
							RequestUseCase.post(endpoints.barcodes.addBarcode, data)
								.then((response) => {
									resl(response);
								})
								.catch((err) => {
									rej(err);
								});
						} else {
							resl(true);
						}
					})
			);

			Promise.all(promesas)
				.then(() => {
					resolve(
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general'),
							text: formatterText('alert.message.confirm.updated.general'),
							confirmButtonText: formatterText('alert.button.continue'),
							allowOutsideClick: false,
							executeFunction: () => navigate(paths.products)
						})
					);
				})
				.catch((err) => {
					if (err?.response?.status === 412) {
						reject(HandleOnError(err?.response?.data));
					}
					if (err?.response?.status === 400) {
						HandleOnError(formatterText('alert.message.barcode.error'));
					} else {
						reject(HandleOnError(formatterText('snackbar.error.process.failed.general')));
					}
					console.error(err);
				});
		} else {
			resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('alert.message.confirm.updated.general'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.products)
				})
			);
		}
	};

	const [validate, setValidate] = useState(false);

	const validateFieldFormProvData = () => {
		const { costo, iva, idUnidadMedida, idMoneda, idProveedor } = formCostProductProviderData;
		const result = costo && iva && idUnidadMedida && idMoneda && idProveedor;
		setValidate(result);
	};

	useEffect(() => validateFieldFormProvData(), [formCostProductProviderData]);
	const handleAddCostProduct = () => {
		if (!isEmpty(validate)) {
			const existData = currentDataTable.filter((data) => {
				return (
					(data.idProveedor?.value === formCostProductProviderData.idProveedor?.value &&
						data.idUnidadMedida.value === formCostProductProviderData.idUnidadMedida.value) ||
					(data.idProveedor === formCostProductProviderData.idProveedor &&
						data.idUnidadMedida === formCostProductProviderData.idUnidadMedida)
				);
			});
			if (existData.length > 0) {
				HandleOnError(
					formatterText(
						'alert.message.error.costProduct',
						'Ya se creo un costo con estos parametros'
					)
				);
			} else {
				setCurrentDataTable([...currentDataTable, formCostProductProviderData]);
				setResultsTableSearch([...currentDataTable, formCostProductProviderData]);
				setFormCostProductProviderData({
					costo: '',
					idMoneda: '',
					iva: '',
					idPrecioProductoProveedor: uuidv4()
				});
				setAuxData((prevState) => ({
					...prevState,
					idUnidadMedida: null,
					idProveedor: null,
					idMoneda: null
				}));

				const DATA = {
					idPrecioProductoProveedor: null,
					idProveedor: {
						idProveedor: formCostProductProviderData?.idProveedor?.value
					},
					idProducto: {
						idProducto: id
					},
					idUnidadMedida: {
						idUnidadMedida: formCostProductProviderData?.idUnidadMedida?.value
					},
					idMoneda: {
						idMoneda: formCostProductProviderData?.idMoneda?.value
					},
					costo: rawCurrency(formCostProductProviderData?.costo),
					iva: rawCurrency(formCostProductProviderData?.iva) || 0,
					usuarioCreacion: parseInt(Cookie.get('idUsuario'))
				};

				RequestUseCase.post(endpoints.costProductProvider.addCostProductProvider, DATA)
					.then(() => {
						getDataMiniTable(id);
						newItemCreated();
						setIva('');
						setCosto('');
					})
					.catch(() => {
						errorProcess();
					});
			}
		} else {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
		}
	};

	const getAllBarcodes = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.barcodes.getAllBarcodes)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCodigoBarras,
							label: item.codigo,
							isFixed: true
						});
					}
				});
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const handleChangeMulti = (val) => {
		setSelectorMultiValues(val);

		// Filtrar los elementos del array 'val' que cumplan con la expresión regular CODEREGEXBARCODE
		const valNew = val.filter((elem) => elem.value.toString().match(CODEREGEX));

		setSelectorMultiValues(valNew);

		if (auxSelectorMulti > val.length) {
			setAuxSelectorMulti(val.length);
			const arrayToDelete = selectorMultiValues.filter((item) => !val.includes(item));

			RequestUseCase.delete(endpoints.barcodes.deleteBarcode(arrayToDelete[0].value))
				.then(() => {
					const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
					localStorage.setItem(
						'dataUpdate',
						JSON.stringify({
							...dataUpdate,
							codigoBarras: val
						})
					);
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			// Hacer algo más en caso de que no se cumpla la condición de longitud
		}
	};

	const formatSelectorMultiValues = (values) => {
		// Verificar si `values` es un arreglo y tiene elementos
		if (Array.isArray(values) && values.length > 0) {
			// Mapear sobre los valores y formatearlos según sea necesario
			return values.map((item) => {
				// Verificar si el valor es una cadena antes de llamar a `toLowerCase()`
				const formattedItem = {
					...item,
					label: typeof item.label === 'string' ? item.label.toLowerCase() : item.label,
					value: typeof item.value === 'string' ? item.value.toLowerCase() : item.value
				};
				return formattedItem;
			});
		}
		// Si `values` no es un arreglo o no tiene elementos, devolverlo como está
		return values;
	};

	const handleCostoChange = (event) => {
		const inputCosto = event.target.value;
		const costoFormateado = inputCosto.replace(/[^\d,]/g, '').replace(/(,.*)\,/g, '$1');
		setCosto(costoFormateado);

		const costoDecimal = parseFloat(costoFormateado.replace(',', '.'));
		const costoConDosDecimales = costoDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
		setFormCostProductProviderData({
			...formCostProductProviderData,
			[event.target.name]: `$${costoConDosDecimales}`
		});
	};

	const formatCurrency = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `,${parts[1]}`;
		}
		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};

	const handleIvaChange = (event) => {
		const inputIva = event.target.value;

		const ivaFormateado = inputIva.replace(/[^\d,]/g, '');
		setIva(ivaFormateado);

		const ivaDecimal = parseFloat(ivaFormateado.replace(',', '.'));
		const ivaConDosDecimales = ivaDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setFormCostProductProviderData({
			...formCostProductProviderData,
			[event.target.name]: ivaConDosDecimales
		});
	};

	const formatIvaField = (value) => {
		const taxs = value.toString().split('.');
		let taxValue = `${taxs[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (taxs.length > 1) {
			taxValue += `,${taxs[1]}`;
		}
		const Decimal = taxValue.split(',');
		const intPart = Decimal[0];
		const floatPart = Decimal[1];

		if (floatPart) {
			taxValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return taxValue;
	};

	useEffect(() => {
		if (auxData.idProveedor !== null || auxData.idUnidadMedida !== null) {
			setFormCostProductProviderData({
				...formCostProductProviderData,
				idProveedor: auxData.idProveedor,
				idUnidadMedida: auxData.idUnidadMedida,
				idMoneda: auxData.idMoneda
			});
		}
	}, [auxData]);

	useEffect(() => {
		if (!open) {
			getProductById(id);
			getDataMiniTable(id);
		}
	}, [open]);

	return {
		active,
		auxData,
		costo,
		currentDataTable,
		formatCurrency,
		formatIvaField,
		formatterText,
		formCostProductProviderData,
		formData,
		getDataMiniTable,
		handleAddCostProduct,
		handleChangeMulti,
		handleClose,
		handleCostoChange,
		handleIvaChange,
		handleOpen,
		handlerTextDescription,
		handleSubmit,
		handleText,
		iva,
		loading,
		myForm,
		navigate,
		open,
		selectedSearch,
		selectorMultiValues,
		selectorValues,
		selectValues,
		setActive,
		setAuxData,
		setCurrentDataTable,
		style,
		titlesTableCostProduct,
		formatSelectorMultiValues,
		handleSku
	};
}
