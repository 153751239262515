import _ from 'lodash';
import { CurrentTechModel } from 'models/Schedule/CurrentTechModel';
import moment from 'moment';

const useAvailabilityTechnicians = () => {
	/**
	 *
	 * @param {*} startDate
	 * @param {*} endDate
	 * @param {*} type
	 * @returns
	 */
	const getDaysInRange = (startDate, endDate) => {
		const type = 'days';
		const fromDate = moment(startDate);
		const toDate = moment(endDate);
		const diff = toDate.diff(fromDate, type);

		return Array.from({ length: diff + 1 }, (_, i) =>
			moment(startDate).add(i, type).format('MM/DD/YYYY')
		);
	};

	const separateDays = (daysInRage) => {
		// crea un arreglo de 7 elemtos -keys(0-7) - values([])
		const daysOfWeek = Object.fromEntries(Array.from({ length: 7 }, (_, i) => [i, []]));
		try {
			for (const _day of daysInRage) {
				const dayWeek = moment(_day).day();
				daysOfWeek[dayWeek].push(_day);
			}

			return {
				domingo: daysOfWeek[0],
				lunes: daysOfWeek[1],
				martes: daysOfWeek[2],
				miercoles: daysOfWeek[3],
				jueves: daysOfWeek[4],
				viernes: daysOfWeek[5],
				sabado: daysOfWeek[6]
			};
		} catch (error) {
			console.log(error);
		}
	};

	const segmentTechsByDayOfWeek = (sortTechnicians) => {
		const daysOfWeek = Object.fromEntries(Array.from({ length: 7 }, (_, i) => [i + 1, []]));

		sortTechnicians.forEach((tech) => {
			tech.franjaHoraria.forEach((item) => {
				const currentTech = new CurrentTechModel(tech, item);

				const techIndex = daysOfWeek[item.idDiaSemana].findIndex(
					(item) => item.idTecnico === tech.idTecnico
				);

				if (techIndex !== -1) {
					const clone = daysOfWeek[item.idDiaSemana][techIndex].clone;
					daysOfWeek[item.idDiaSemana][techIndex].clone = [...clone, currentTech];
				} else {
					daysOfWeek[item.idDiaSemana].push({
						...currentTech,
						clone: []
					});
				}
			});
		});

		// Generar las propiedades del objeto de retorno con un bucle
		const result = {};
		const nameDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		Array.from({ length: 7 }, (_, i) => {
			const dayOfWeek = nameDays[i];
			result[`techTimeZone${dayOfWeek}`] = daysOfWeek[i + 1];
			return i;
		});

		return result;
	};

	// Crear una función para procesar los datos de un día de la semana
	const processDayOfWeek = (dayArr, techTimeZone, dayOfWeekName) => {
		const processedData = [];

		dayArr.forEach((itemDate) => {
			techTimeZone.forEach((techWeekDay) => {
				const FI = moment(`${techWeekDay.fechaInicio}`).format('MM/DD/YYYY');
				const FF = moment(`${techWeekDay.fechaFin}`).format('MM/DD/YYYY');

				const { isInRange, franjaHorariaDefinitiva } = _generateFranjaHorariaDefinitiva(
					FI,
					FF,
					itemDate,
					techWeekDay
				);

				const totalHours = _calculateTotalHours(franjaHorariaDefinitiva);

				if (isInRange) {
					const obj = {
						...techWeekDay,
						itemDate,
						totalHours,
						franjaHorariaDefinitiva
					};

					processedData.push(obj);
				}
			});
		});

		return {
			[dayOfWeekName]: processedData
		};
	};

	const generateTimeZones = (techData) => {
		// Extraer datos relevantes de techData
		const {
			nombreAgenda,
			idAgenda,
			nombreFranjaHoraria,
			idTipoAgenda,
			idHoraInicio,
			idHoraFin,
			clone,
			fechaInicio,
			fechaFin
		} = techData;

		// Crear un array de 24 horas
		const hours = Array.from(Array(24).keys());

		// Calcular las horas disponibles en la franja horaria principal
		const startHour = parseInt(idHoraInicio.split(':')[0]);
		const endHour = parseInt(idHoraFin.split(':')[0]);
		const availableHours = calculateListHours(startHour, endHour);

		// Determinar el color de la franja horaria
		const color =
			idTipoAgenda === 1
				? `A;;${nombreAgenda};;${idAgenda};;${nombreFranjaHoraria}`
				: `I;;${nombreAgenda};;${idAgenda};;${nombreFranjaHoraria}`;

		// Generar la franja horaria definitiva para la franja principal
		const franjaHorariaDefinitiva = generateHourlyAvailability(hours, availableHours, color);

		// Verificar si existen clones
		if (clone.length > 0) {
			clone.forEach((cloneData) => {
				const {
					idHoraInicio: cloneStartHour,
					idHoraFin: cloneEndHour,
					nombreAgenda: CloneName,
					idAgenda: CloneIDAgenda,
					nombreFranjaHoraria: CloneNameTimeZone,
					fechaInicio: cloneFechaInicio,
					fechaFin: cloneFechaFin
				} = cloneData;

				// Verificar si hay colisión entre la franja principal y el clon
				const collides = _verifyCollisionBetweenDates(
					fechaInicio,
					fechaFin,
					cloneFechaInicio,
					cloneFechaFin
				);

				if (collides) {
					// Calcular las horas disponibles en el clon
					const cloneAvailableHours = calculateListHours(
						parseInt(cloneStartHour.split(':')[0]),
						parseInt(cloneEndHour.split(':')[0])
					);

					// Determinar el color del clon
					const cloneColor =
						cloneData.idTipoAgenda === 1
							? `A;;${CloneName};;${CloneIDAgenda};;${CloneNameTimeZone}`
							: `I;;${CloneName};;${CloneIDAgenda};;${CloneNameTimeZone}`;

					// Actualizar la franja horaria definitiva considerando el clon
					franjaHorariaDefinitiva.forEach((hourValue, hourIndex) => {
						const hourAvailable = cloneAvailableHours.includes(hourIndex);
						if (hourAvailable) {
							const currentHourValue = hourValue.split(';;')[0];
							const updatedHourValue = hourAvailable
								? `${hourIndex}${cloneColor}`
								: `${hourIndex}N`;
							franjaHorariaDefinitiva[hourIndex] = currentHourValue.includes('N')
								? updatedHourValue
								: currentHourValue;
						}
					});
				}
			});
		}

		return franjaHorariaDefinitiva;
	};

	// Función para generar la disponibilidad horaria
	const generateHourlyAvailability = (hours, availableHours, color) => {
		return hours.map((hour) => {
			const hourAvailable = availableHours.includes(hour);
			return hourAvailable ? `${hour}${color}` : `${hour}N`;
		});
	};

	// Función para calcular la lista de horas disponibles
	const calculateListHours = (startHour, endHour) => {
		const listHours = [];
		for (let i = startHour; i <= endHour; i++) {
			listHours.push(i);
		}
		return listHours;
	};

	const _verifyCollisionBetweenDates = (FIC, FFC, FIO, FFO) => {
		// CASE 1: FIC = "01/01/2023" FFC = "01/31/2021" FIO = "02/02/2021" FFO = "25/02/2021"
		// RESULT: false because the dates of the current tech are not in the range of the old tech
		// CASE 2: FIC = "01/01/2021" FFC = "01/31/2021" FIO = "01/025/2021" FFO = "25/02/2021"
		// RESULT: true because the dates of the current tech are in the range of the old tech
		const startCurrent = _formatMoment(FIC);
		const endCurrent = _formatMoment(FFC);
		const startOld = _formatMoment(FIO);
		const endOld = _formatMoment(FFO);

		return (
			startCurrent.isBetween(startOld, endOld) ||
			endCurrent.isBetween(startOld, endOld) ||
			(startCurrent.isSame(startOld) && endCurrent.isSame(endOld))
		);
	};

	const _generateFranjaHorariaDefinitiva = (FIA, FFA, D, TECH) => {
		// Verificar si la fecha está en el rango de la agenda
		const isInRange = FIA <= D && FFA >= D;

		if (isInRange) {
			// Calcular FranjaHorariaDefinitiva si está en el rango
			const franjaHorariaDefinitiva = generateTimeZones(TECH);

			return {
				isInRange: true,
				franjaHorariaDefinitiva
			};
		} else {
			// No hay franjas horarias para ese día si no está en el rango
			return {
				isInRange: false,
				franjaHorariaDefinitiva: []
			};
		}
	};

	/**
	 *
	 * @param {*} FHD Franja Horaria Definitiva
	 * @returns
	 */
	const _calculateTotalHours = (FHD) => {
		// Utilizar lodash para contar elementos válidos que no incluyan 'N'
		const validFranjas = _.filter(FHD, (franja) => !franja.includes('N'));
		const totalHours = validFranjas.length;
		return totalHours;
	};

	const _formatMoment = (date) => {
		return moment(date, 'YYYY-MM-DD');
	};

	return {
		getDaysInRange,
		separateDays,
		segmentTechsByDayOfWeek,
		processDayOfWeek
	};
};

export default useAvailabilityTechnicians;
